/*  este componente vai conter todos os cotext para não ficar poluido o App.tsx */
// ele vai ser importado no app 

import React from 'react';

import {AuthProvider } from './auth';

import {ToastProvider} from './toast';

const AppProvider: React.FC = ({children}) => {

  return (
    <AuthProvider>
      <ToastProvider>{children}</ToastProvider>
    </AuthProvider>    
  );
}

export default AppProvider;

