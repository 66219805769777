import React, { useCallback, useRef } from 'react';
import {FiArrowLeft,FiMail,FiUser,FiLock} from 'react-icons/fi';
//import logoImg from '../../assets/logo.svg';


import {Form} from '@unform/web';

import Input from '../../components/Input';

import Button from '../../components/Button';

import * as Yup from 'yup';

import {FormHandles} from '@unform/core'; // tipagem de todas as funções

import getValidationErrors from '../../utils/getValidationErrors';

import {Link, useHistory}  from 'react-router-dom';

import api from '../../services/api';

import {Container,Content,AnimationContainer} from './styles';

import {useToast} from '../../hooks/toast';

interface SignUpFormData {
  name:string;
  email:string;
  password:string;
}

const SignUp: React.FC = () => {

  const formRef = useRef<FormHandles>(null);  

  const {addToast} = useToast();
  const history  = useHistory();


    const handleSubmit = useCallback(async (data: SignUpFormData) => {
    console.log(data);	   
     try {
        formRef.current?.setErrors({}); // limpando os formulários de erros.
  	    const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.string()
          .email('Digite um e-mail válido')
          .required('E-mail obrigatório'),
        password: Yup.string().min(6, 'No mínimo 6 dígitos'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      
      await api.post('/users',data);

      history.push('/');

      addToast({
        type:'success',
        title:'Cadastro realizado!',
        description:'Você ja pode fazer o seu logon no Gobarber.'
      })
      

      
    } catch (err) {
      
      if (err instanceof Yup.ValidationError){ // se for erro do usuario 

        const errors = getValidationErrors(err); // retorna um erro personalizado     
  
        formRef.current?.setErrors(errors);

        return;
      }          
      // disparar um toast
      addToast({
        type:'error',
        title:'erro no cadastro',
        description:'Ocorreu um erro ao fazer cadastro',                
      });  
    }

  }, [addToast, history]);

return(  
<Container>
          
            <Content>
                <AnimationContainer>
                    {/* <img  src={logoImg} alt="Gobarber"/> */}
                    <Form ref={formRef} onSubmit={handleSubmit}>
                      <h1>Faça seu cadastro</h1>
                      <Input name="name" icon={FiUser} placeholder="Nome"/>
                      <Input name="email" icon={FiMail} placeholder="E-mail"/>
                      <Input name="password" icon={FiLock} type="password" placeholder="Senha" />
                      <Button type="submit">Cadastrar</Button>
                    </Form>
                    <Link  to="/">
                      <FiArrowLeft/>
                      Voltar para login</Link>
                </AnimationContainer>
            </Content>

</Container>
);

};

export default SignUp;