import { shade } from 'polished';
import styled  from 'styled-components';



export const Container = styled.div `
  height:100vh; // 100% do view port hight

 > header{

    height: 144px; // 64px;
    background:#28262e;

    display:flex;
    align-items:center;


    div{
      width:100%;
    //  max-width: 1120px;
   
      margin: 0 auto;

      svg{
        margin-left: 25px;
        color:#999591;
        width:40px;
        height:40px;        
      }

    }


  }

`;



export const Content = styled.div `
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  margin: -170px auto 0 ; // top, botton lados pra centralizar

  place-content:center;
  max-width:700px;
  width:100%;


  form{
  margin: 80px  0;
  margin-bottom:24px;
  width:340px;
  text-align:center;
  display:flex;
  flex-direction:column;

  h1{
    margin-bottom:24px; 
    font-size:20px;
    text-align: left;

  }
  
}

`;

export const AvatarInput = styled.div`
  margin-bottom:32px;
  position:relative; 
  align-self: center;

  img{
    width:186px;
    height:186px;
    border-radius:50%;
      
  }

  label{ 
    position: absolute;
    width:48px;
    height:48px;
    background: #ff9000;
    border-radius:50%;
    right:0;
    bottom:0;
    border:0;
    cursor: pointer;
    transition:background-color 0.2s;

    // colocando a maquina fotografica bem no meio;
    display:flex;
    align-items:center;
    justify-content:center;

    input{
      display:none;
    }



    svg{
      width:20px;
      height:20px;
      color: #312e38;
    }

    &:hover{
      background: ${shade(0.2, '#ff9000')}
    }
  }




`;




