import React, { createContext, useContext, useCallback, useState } from 'react';

import ToastContainer from '../components/ToastContainer';

import { v4 as uuid } from 'uuid';

export interface ToastMessage{
  id:string;
  type?:'info'|'success'|'error'; 
  title: string;
  description?:string;
}

interface ToastContextData{
  addToast(message: Omit<ToastMessage, 'id'>):void;
  removeToast(id:string):void;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);


const ToastProvider: React.FC = ({children}) => {

  const [messages, setMessages]  = useState<ToastMessage[]>([]);

  const addToast = useCallback(({type, title, description}:Omit<ToastMessage, 'id'>)=>{ // pegar receber todos os parametros menos o id
    const id = uuid(); 
    const toast = {
      id,
      type,
      title,
      description,
    }    
    
    setMessages(state=>[...state,toast]);
    
  },[]);

  const removeToast = useCallback((id:string)=>{
    // retorna todas as messagens menos a que tem o id recebido na função    
    setMessages(state => state.filter(message=> message.id !== id)); 

  },[]);
  

  return (
    <ToastContext.Provider value={{addToast,removeToast}}>
      {children} 
      <ToastContainer messages={messages}/>
    </ToastContext.Provider>
  );

}

function useToast():ToastContextData{

  const context = useContext(ToastContext);

  if (!context){ // se ele não existir entra

    throw new Error('O useContext foi usazdo fora, ele preciosa estar em volta');
    
  }
  return context;

}

export {ToastProvider,useToast}