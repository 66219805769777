import React from 'react';
import {
    Route as ReactDOMRoute,
   RouteProps as ReactDOMRouteProps,
   Redirect   
  } from 'react-router-dom'; //RouteProps para pegar as variaveis , mudamos o nome de RouteProps para ReactDOMRouteProps para não dar conflito com a interface, não sei por ão pode ser outro nome


import { useAuth } from '../hooks/auth';

interface RouteProps extends ReactDOMRouteProps{ // estendendo a classe RouteProps e colocando em uma interface com o mesmo nome
  isPrivate?: boolean;
  component: React.ComponentType; // ele espera componentes </> isso sobescrteve para receber somente o nome
}

// true/true = ok
// true/false = redirecionar para o login
// false/true = redirecionar para o Dashboard 
// false/false = ok



const Route: React.FC<RouteProps> = ({isPrivate = false, component: Component, ...rest}) => { //...rest pega todas as outras proprietades component: Component transformma em componente com a letra maiuscula para poder ysar
  const { user }  = useAuth(); // se tiver algum dado dentro de user o usuario está atutenticado

  return(<ReactDOMRoute 
    {...rest} 
    render={({location})=>{
      return isPrivate ===  !!user ? (
        <Component/>
      ):(
        <Redirect to={{pathname:isPrivate ? '/': '/ini', // setiver autencidado vai para ini
         state: {from:location}      
      }}/>
      );
    }}
    />);

 
};

export default Route;