import styled, {css} from 'styled-components';

import Tooltip  from '../Tooltip';

interface ContainerProps{
  isFocused:boolean;
  isFilled:boolean;
  isErrored:boolean;
}

export const Container = styled.div<ContainerProps>`
 
  background: #ffff;
  border-radius: 4px;
  padding: 16px;
  opacity:1;

  border: 1px solid #666360;
  color: #312e38;

  width: 100%;
  display: flex;
  align-items: center;
  & + div {
    margin-top: 8px;
  }

  ${props => props.isErrored && css ` // se o isErrored for true então "&&" a cor da borda fica vermelha
    border-color:#c53030;
    
   `} 

  ${props => props.isFocused && css ` // se o isFocused for true então "&&"
  color:#1769aa;
  border-color:#1769aa;
  border: 2px solid;
   ` } 

   ${props => props.isFilled && css ` // se o isFocused for true então "&&"
  //color:#1769aa; 
   ` } 



   
  input {
    flex: 1; // fazer ocupar todo o espaço disponivel
    background: transparent;
    border: 0;
   // color: #666360; // cor do texto
    &::placeholder {
      color: #666360;
    }
  }
  svg {
    margin-right: 16px;
  }





  
`;


export const Error = styled(Tooltip)`
  height: 20px;       // pra não ficar mexendo
  margin-left: 16px; // pra deixar o texto do imput mais longe do icone de erro

   svg{
    margin:0;
  }
  

  span{
    background: #c53030;
    color: #fff;

    &::before{
      border-color: #c53030 transparent;
    } 

  }
`;