// recebe um objeto de erro
// retorna um objeto personalizado com os erros
// nome do campo, onde a messagem deve aparecer e a messagem para o usuario.

import {ValidationError} from 'yup'; // importas os tipos dos erros

interface Errors{
  [key:string]:string; // a key pode ser qualquer coisa e valor pode ser qualquer coisa
}

export default function getValidationErrors(err:ValidationError):Errors{

  const validationErrors:Errors = {};

  err.inner.forEach((error)=>{

    validationErrors[error.path] = error.message;       

  });
  
  return validationErrors;
}