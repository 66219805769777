import React,  { createContext, useCallback, useContext, useState } from 'react';

import api from '../services/api';

interface SignInCredentials{
  email:string;
  password:string;
}

interface User{
  id: string;
  name:string;
  avatar_url:string;
  email:string;
}

interface AuthContextData {
  user:User;
  sigIn(credential: SignInCredentials):Promise<void>;
  signOut():void;
  updateUser(user:User):void;
}

interface AuthState{
  token:string;
  user:User;  
}

 const AuthContext = createContext<AuthContextData>({} as AuthContextData); 

 const AuthProvider: React.FC  = ({children}) =>{

      const [data, setData] = useState<AuthState>(()=>{
          const token = localStorage.getItem('@GoBarber:token');
          const user = localStorage.getItem('@Gobarber:user');

          
          if (token && user){
            
            api.defaults.headers.authorization = `Bearer ${token}`;

            return {token, user:JSON.parse(user)}; //token:token (só pra lembrar)
          }
            return{} as AuthState; // retorna um objeto vazio do tipo AuthState

    });

    const sigIn = useCallback(async({ email, password })=>{
          const reponse = await api.post('sessions',{
            email,
            password,
          });
        

          const {token, user} = reponse.data;

          
          localStorage.setItem('@GoBarber:token',token);
          localStorage.setItem('@Gobarber:user',JSON.stringify(user));
          
          api.defaults.headers.authorization = `Bearer ${token}`;
          
          setData({token,user});  
  },[]);

  const signOut = useCallback(() =>{

    localStorage.removeItem('@GoBarber:token');

    localStorage.removeItem('@Gobarber:user');

    setData({} as AuthState);    

  },[]);

  const updateUser = useCallback((    
    user:User )=>{
      localStorage.setItem('@Gobarber:user',JSON.stringify(user));
      setData({
        token: data.token,
        user,
      })
    },[data.token]);


  

  return(
    <AuthContext.Provider value={{user:data.user, sigIn, signOut, updateUser}}>
      {children}
    </AuthContext.Provider>
  );

};

function useAuth():AuthContextData{
    const context = useContext(AuthContext);

    if (!context){ // se o contexto não foi criado gera um error
      throw new Error ('useAuth deve ser usado em um AuthProvider');
    }

    return context;
}  



export {  AuthProvider, useAuth };



