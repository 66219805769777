import styled, { keyframes }  from 'styled-components';



import { shade  } from 'polished';

export const Container = styled.div `
  height:100vh; // 100% do view port hight

  display: flex;
  align-items: stretch; // itens um ao lado do outro

  justify-content: center; // linhamento da vertical 
  align-items: center; // colocar no meio


`;


const appearFromRigth = keyframes`
  from {
    opacity:0;
    transform: translatex(50px);
  }

  to{
     opacity:1;
     transform:translatex(0);
  }

`;

export const Content = styled.div `
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;

place-content:center;
max-width:700px;
width:100%;
`;

export const AnimationContainer = styled.div`

animation: ${appearFromRigth} 1s;

display:flex;
flex-direction:column;
align-items:center;
justify-content:center;



form{
  margin: 80px  0;
  margin-bottom:24px;
  width:340px;
  text-align:center;
  h1{
    margin-bottom:24px; // espaço em baixo do Faça seu logon
  }

  



  a { // esqueci a senha;

    color:#F4EDE8;
    display: block;
    margin-top:24px;
    text-decoration:none;
    transition: color 0.2s;
    &:hover{
      color:${shade(0.2, '#F4EDE8')} // no evento hover escurrcer 20% da cor
    }
  }


  }

  
> a{
    color:#F4EDE8;
    display: block;
    margin-top:20px;
    text-decoration:none;
    transition: color 0.2s;
    
    display:flex;
    align-items:center;

    svg{
      margin-right: 16px;
    }

    &:hover{
      color:${shade(0.2, '#F4EDE8')} // no evento hover escurrcer 20% da cor
    }
}

`;


