import React, {useEffect} from 'react';

import {  
  FiAlertCircle,
  FiCheckCircle,
  FiInfo,
  FiXCircle} from 'react-icons/fi';
  
  import { ToastMessage, useToast  } from '../../../hooks/toast';
  
  import {Container} from './styles';
  
  interface ToastProps{
    message:ToastMessage;
    style:object;
  }

  const icons = {
    info:<FiInfo size={24}/>,
    error:<FiAlertCircle size={24}/>,
    success:<FiCheckCircle size={24}/>,     
  };

  const Toast: React.FC<ToastProps> = ({ message, style }) => {
  
  const {removeToast} = useToast(); // removeToast função que remove o toast

  useEffect( () => {

    const timer = setTimeout(()=>{removeToast(message.id)},3000);   // setTimeout(()=>{função}, enquanto tempo) , 

    return () => { clearTimeout(timer); } // só coloca return quando queremos executar uma arrow fucntion quando useEffect morrer

  },[removeToast,message.id]);   

  return (
    
    <Container type={message.type}  hasDescription={!!message.description} style={style}>      
       
      {icons[message.type || 'info']}

       <div>
         <strong>{message.title} </strong>
           {message.description && <p>{message.description}</p>}        
       </div>

       <button onClick={()=>removeToast(message.id)} type="button">
           <FiXCircle size={20}/>         
      </button>   
    </Container>
  );
};

export default Toast;