

import React from 'react';
//import {FiArrowLeft} from 'react-icons/fi';
//import { Link }  from 'react-router-dom';
import {Container,Content, UserLogado, Botao,Esquerda,Direita} from './styles';
//import { useAuth } from '../../hooks/auth';

import img_minharadio from '../../assets/img_minharadio.png';
import ini_anunciar from '../../assets/ini_anunciar.png';
import ini_audios from '../../assets/ini_audios.png';
import ini_player_desktop from '../../assets/ini_player_desktop.png';
import ini_ajuda from '../../assets/ini_ajuda.png';
import ini_sair from '../../assets/ini_sair.png';
import ini_bancoBB from '../../assets/ini_bancoBB.png';


const Ini: React.FC = () => {

  //const {user,updateUser} = useAuth();


return(  
<Container>
    <header>
      <div>
        {/* <Link to="/dashboard">
          <FiArrowLeft/>
        </Link> */}
        <h1>Ambiente FM</h1>
       
      </div>

        <UserLogado>          
          <p>Usuario:<span>Felipe Mesquita</span></p>        
          <p>Premissão:<span>Ouvinte</span></p>         
        </UserLogado>         
    </header>  
             
     <Content>   
       <Esquerda>   
        <Botao>
          <img src={img_minharadio} alt="Minha Rádio"/>
          <span>Minha Rádio</span>
        </Botao>
        <Botao>
          <img src={ini_anunciar} alt="Anunciar"/>
          <span>Anunciar</span>
        </Botao>
        <Botao>
          <img src={ini_audios} alt="Áudios"/>
          <span>Áudios</span>
          
        </Botao>
        <Botao>
          <img src={ini_player_desktop} alt="Player Desktop"/>          
          <span>Player Desktop</span>
        </Botao>
        <Botao>
          <img src={ini_ajuda} alt="ini_ajuda"/>
          <span>Ajuda</span>         
        </Botao>
        <span></span>
               
       </Esquerda>  
       <Direita>   
       <Botao>
          <img src={ini_sair} alt="Sair"/>
          
          <span>Sair</span>
        </Botao>
       <Botao>
          <img src={ini_bancoBB} alt="Boletos"/>          
          <span>Boletos</span>
        </Botao>
         
       </Direita>     
    </Content>

</Container>
);

};

export default Ini;