import React from 'react';

import Toast from './Toast'

import {Container} from './styles';
import { useTransition } from 'react-spring'

import {ToastMessage} from '../../hooks/toast';



interface ToastContainerProps{
  messages:ToastMessage[];
}



const ToastContainer:React.FC<ToastContainerProps> = ({messages}) =>{

  const messagesWithTransitions = useTransition(
    messages, 
    messages => messages.id, // o segundo parametro é uma função que retorna cada id das minhas mensagens
    {                       // terceiro parametro é o css das animações
        from: {right: '-120%', opacity:0}, // inicio do movimento colocar quando css precisar aqui
        enter:{right: '0%',opacity:1 },   // onde ele deve ai parar
        leave:{right: '-120%', opacity:0},
    })


  return(     
    <Container> 
      {messagesWithTransitions.map(({item, key, props }) => (
        <Toast key={key} style={props} message={item} />       
      ))} 
    </Container>     
  );
};

export default ToastContainer;