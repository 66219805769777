import React from 'react';
import Route from './Route';

import {Switch} from 'react-router-dom'; //Switch para exibir  uma rota por vez, rote as rotas

import SignIn from '../pages/Signin';

import SignUp from '../pages/SignUp';

import DashBoard from '../pages/DashBoard';

import ForgotPassword from '../pages/ForgotPassword';

import ResetPassword from '../pages/ResetPassword';

import Profile from '../pages/Profile';

import Ini from '../pages/Ini';


const routes: React.FC = () => (
  
  <Switch>
    <Route path="/" exact component={SignIn}/>
    <Route path="/signup"  component={SignUp}/>
    <Route path="/forgot-password"  component={ForgotPassword}/>
    <Route path="/rest_password"  component={ResetPassword}/>


    <Route path="/dashboard"  component={DashBoard} isPrivate/>    
    <Route path="/profile"  component={Profile} isPrivate/>    
    <Route path="/ini"  component={Ini} isPrivate/>    
        
  </Switch>
  
  );


export default routes;