import styled , {css} from 'styled-components';

import {animated} from 'react-spring';

interface ContainerProps{
  type?:'info'|'success'|'error'; 
  hasDescription:boolean;
}

const toastTypesVariations ={
  info:css`
  background:#ebf8ff;
  color:#3172b7;
  `,

  success:css`
  background:#e6fffa;
  color:#2e656a;
  `,
  
  error:css`
  background:#fddede;
  color:#c53030;
  `,
};


export const Container = styled(animated.div)<ContainerProps>`
      width:360px;
      font-size:18px;
      position:relative;
      padding: 
      16px // em  cima 
      30px // na direita 
      16px // em baixo
      16px; // na esquerda
      border-radius: 10px;
      box-shadow: 2px 2px 8px rgba(0,0,0,0.2);
      display:flex;
      background:#ebf8ff;// cor do fundo
      color:#3172b7;    // cor da fonte
      & + div{
        margin-top: 8px;
    }

    ${(props) => toastTypesVariations[props.type ||'info' ] }

    >svg{ //  ">" a setinha indica que é para aplicar o estilo diretamente nos icones dentro do toasts não no container
        margin: 
        4px // top
        12px // da direira
        0 // em baixo
        0; // na esquerda
    }

    div{
      flex: 1;      // ocupar todo os tamanho possivel

      p{
      margin-top: 4px; // distancia do top
      font-size:14px; // famanho da fonte
      opacity: 8.0;
      line-height: 20px; // distanciar o texto
      }
    }

    button {
      position:absolute;
      right:16px; // joga tudo pra direita com um espaço de 8px;
      top:19px; // distanciar do top
      opacity: 0.6; // mais trasparente
      //resetar as propriedades que um botão tem  por padrão
      border:0; // 
      background: transparent; // 
      color:inherit; // PEGA A COR QUE ESTÁ SENDO USADA no container
    }

    ${(props) => !props.hasDescription && // explicação em https://www.notion.so/Gobarber-Web-1be3b27c5ab04da7b5b16ff2fb3fa01f#bcfc52742d1441c78d2fe158b5f4211d

    css` 
      align-items:center;  
      svg{
        margin-top:0;      
      }   
    `}
`;
