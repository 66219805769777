import React, { useCallback, useRef } from 'react';



import {FiLogIn,FiMail,FiLock} from 'react-icons/fi';

import {Form} from '@unform/web';

import Input from '../../components/Input';

import Button from '../../components/Button';

import {FormHandles} from '@unform/core'; // tipagem de todas as funções

import * as Yup from 'yup';

import getValidationErrors from '../../utils/getValidationErrors';

import {useAuth} from '../../hooks/auth';

import {useToast} from '../../hooks/toast';

import {Container,Content, AnimationContainer} from './styles';

import {Link, useHistory}  from 'react-router-dom'; // pra fazer a nevagação

interface SignInFormData{
  email:string;
  password:string;
}

const SignIn: React.FC = () => {

  const history = useHistory();

  const { sigIn} = useAuth();  

  const { addToast } = useToast();  

  const formRef = useRef<FormHandles>(null); 

    const handleSubmit = useCallback(async (data: SignInFormData) => {
       
     try {

        formRef.current?.setErrors({}); // limpando os formulários de erros.

  	    const schema = Yup.object().shape({
        email: Yup.string()
          .email('Digite um e-mail válido')
          .required('E-mail obrigatório'),
        password: Yup.string().required( 'Digite a senha'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await sigIn({
        email:data.email,
        password: data.password,        
      });

      history.push('/ini');


    } catch (err) {

      if (err instanceof Yup.ValidationError){ // se for erro do usuario 

        const errors = getValidationErrors(err); // retorna um erro personalizado     
  
        formRef.current?.setErrors(errors);

        return;
      }          
      // disparar um toast
      addToast({
        type:'error',
        title:'erro na autenticação',
        description:'Ocorreu um erro ao fazer login, cheque as credenciais',                
      }); 
    }

  }, [sigIn,addToast,history]);

  return (
        <Container>
        <Content>
            <AnimationContainer>          
               {/* <img src={logoImg} alt="Gobarber"/> */}
              <Form ref={formRef} onSubmit = {handleSubmit} >
                <h1>Controle da rádio</h1>
                <Input name="email" icon={FiMail} placeholder="E-mail"/>
                <Input name="password" icon={FiLock} type="password" placeholder="Senha" />
                <Button type="submit">Entrar</Button>
                <Link to="/forgot-password">Esqueci minha senha</Link>
              </Form>
              <Link to="/signup">
                <FiLogIn/>
                Criar Conta</Link>
            </AnimationContainer>
        </Content>
        
      </Container>
    );
  }

export default SignIn;