
import styled  from 'styled-components';

import iniBackgroundImg from '../../assets/ini-desktop.jpg';


export const Container = styled.div `
  height:100vh; // 100% do view port hight   
  background:url(${iniBackgroundImg}) no-repeat center; 
  background-size:cover;
 > header{    
    display:flex;
    flex-direction:row;
    flex-wrap:nowrap;
    justify-content: space-between;
    border-radius: 10;

    box-shadow: 5px 5px 5px #19417d;
    height: 55px;
    background:rgba(25, 118, 210, 0.3) ;
   
    display:flex;    
    align-items:center;

    div{ // setinha            
      svg{        
        margin-left: 25px;
        color:#fff;
        width:40px;
        height:40px;        
      }
    }

    h1{
      margin-left: 15px;
      font-size:20px;
    } 

   //opacity:.3;

    
  }
  
`;

export const UserLogado = styled.div `
  font-size:12px;
     
   
   span{
    padding-right:2px;
    color:#16E14F;
    padding-left:2px;
   }
   p{
     padding-right:15px;
   }

`;






export const Content = styled.div `
  display:grid;
  width:100vp;
 
  grid-template-columns: 140px 140px; // quantas colunas eu quero
  justify-content:space-between;  // jogar as colunas para os cantos


  
   
`;

export const Botao = styled.div `
  display:flex;
  flex-direction:column;
  align-items:center;
  margin-top:3vh;

  > img{
    width:6vh;
    height:6vh;  
  }

  > span{
    font-size: 1.8vh;
  }

  


//background-color:yellow;
`;
export const Esquerda = styled.div `
 // display:flex;
 // flex-direction:column;
 justify-items:start;
  
  
  
 // background-color:red;

`;
export const Direita = styled.div `
//background-color:green;
`;









