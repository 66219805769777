import React, { ChangeEvent, useCallback, useRef } from 'react';
import {FiMail,FiUser,FiLock, FiCamera, FiArrowLeft} from 'react-icons/fi';



import {Form} from '@unform/web';

import Input from '../../components/Input';

import Button from '../../components/Button';

import * as Yup from 'yup';

import {FormHandles} from '@unform/core'; // tipagem de todas as funções

import getValidationErrors from '../../utils/getValidationErrors';

import {useHistory, Link }  from 'react-router-dom';

import api from '../../services/api';

import {Container,Content, AvatarInput} from './styles';

import {useToast} from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';

interface ProfileFormData {
  name:string;
  email:string;
  password:string;
  password_confirmation:string;
  old_password:string;
}

const Profile: React.FC = () => {

  const formRef = useRef<FormHandles>(null); 
  const {addToast} = useToast();
  const history  = useHistory();

  const {user,updateUser} = useAuth();


    const handleSubmit = useCallback(async (data: ProfileFormData) => {
    console.log(data);	   
     try {
        formRef.current?.setErrors({}); // limpando os formulários de erros.

  	    const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.string().email('Digite um e-mail válido'),

        // é obrigatório quando a 
        old_password: Yup.string(),
        password: Yup.string().when('old_password', {
          is: val => !!val.length,
          then: Yup.string().required('Campo obrigatório'),
          otherwise: Yup.string(),
        }),

         password_confirmation: Yup.string()
            .when('old_password', {
              is: val => !!val.length,
              then: Yup.string().required('Campo obrigatório'),
              otherwise: Yup.string(),
            })
            .oneOf([Yup.ref('password'), ''], 'Confirmação incorreta'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });



      const formData = Object.assign({
        name:data.name,
        email: data.email,
      }, data.old_password ? {
        old_password: data.old_password,
        password: data.password,
        password_confirmation: data.password_confirmation
       } : {});
      
      const response = await api.put('/profile',formData);

      updateUser(response.data);

      history.push('/ini');

      addToast({
        type:'success',
        title:'Perfil atualizado!',
        description:'Suas informações do perfil foram atualizadas com sucesso!'
      })      

      
    } catch (err) {
      
      if (err instanceof Yup.ValidationError){ // se for erro do usuario 

        const errors = getValidationErrors(err); // retorna um erro personalizado     
  
        formRef.current?.setErrors(errors);

        return;
      }          
      // disparar um toast
      addToast({
        type:'error',
        title:'Erro na atualização ',
        description:'Ocorreu um erro na atualização do perfil, tente novamente',                
      });  
    }

  }, [addToast, history,updateUser]);


  const handleAvatarChange = useCallback((e:ChangeEvent<HTMLInputElement>)=>{

    if (e.target.files){      

      const data = new  FormData();

      data.append('avatar', e.target.files[0]);
      
      api.patch('users/avatar', data).then((response)=>{
     
        updateUser(response.data);
       
        addToast({
          type: 'success',
          title: 'Upload concluido com sucesso!',
          description: 'O Avatar foi atualizado!',
        });
      });      
    }
    
  },[addToast,updateUser]);




return(  
<Container>
    <header>
      <div>
        <Link to="/ini">
          <FiArrowLeft/>
        </Link>
      </div>
    </header>

           
            <Content>                   
                    <Form ref={formRef} initialData={{
                      name: user.name,
                      email: user.email                     

                    }} onSubmit={handleSubmit}>

                      <AvatarInput>
                        <img src={user.avatar_url} alt={user.name}/>
                        <label htmlFor="avatar">
                          <FiCamera/>
                          <input type="file" name="" id="avatar" onChange={handleAvatarChange}/>
                        </label>
                      </AvatarInput>

                      <h1>Meu Perfil</h1>
                      <Input name="name" icon={FiUser} placeholder="Nome"/>
                      <Input name="email" icon={FiMail} placeholder="E-mail"/>

                      <Input 
                      containerStyle={{marginTop:24}}
                      name="old_password" icon={FiLock} type="password" placeholder="Senha atual"/>
                      <Input name="password" icon={FiLock} type="password" placeholder="Nova Senha"/>
                      <Input name="password_confirmation" icon={FiLock} type="password" placeholder="Confimar Senha"/>
                      <Button type="submit">Confirmar mudanças</Button>
                    </Form>
                   
                
            </Content>

</Container>
);

};

export default Profile;