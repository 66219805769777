import React, { useCallback, useEffect, useMemo, useState } from 'react';

import logoImg from '../../assets/logo.svg';

import { FiClock, FiPower } from 'react-icons/fi';

import DayPicker, {DayModifiers} from 'react-day-picker';

import {isToday, format, parseISO, isAfter} from 'date-fns'; // isToday:pega o dia de hoje, format: formatada a data

import ptBR from 'date-fns/locale/pt-BR';

import 'react-day-picker/lib/style.css';

import { 
    Container,
    Header,
    HeaderContet,
    Profile,
    Content,
    Schedule,
    Calendar,
    NextAppointment,
    Section,
    Appointment  
} from './styles';

import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import { Link } from 'react-router-dom';


interface MonthAvailabilityItem {
  day: Number;
  available: boolean;
}

interface AppointmentDTO{
  id:string;
  date: string;
  hourFormatted:string;
  user:{
    name:string;
    avatar_url:string;
  }
}


const DashBoard: React.FC = () => {

  const {user,signOut} = useAuth();

  const [selectedDate, setSelectedDate] = useState(new Date());

  const [currentMonth, setCurrentMonth] = useState(new Date()); // toda vez que o mes auterar

  const [monthAvailability, setmonthAvailability] = useState<MonthAvailabilityItem[]>([]);

  const [appointments,setAppointments] = useState<AppointmentDTO[]>([]);

  const handleDateChange = useCallback((day:Date,modifiers:DayModifiers)=>{    
    if (modifiers.available && !modifiers.disabled){      
      setSelectedDate(day);
    }    
  },[]);

  const handleMonthChange = useCallback((month:Date)=>{
    setCurrentMonth(month);
  },[]);

  useEffect(()=>{
    // ir na api buscar os dados a disponibildade desse mes

    api.get(`/providers/${user.id}/month-availability`,{
      params:{
        year: currentMonth.getFullYear(),
        month: currentMonth.getMonth()+1,
      }
    }).then(response =>{
      setmonthAvailability(response.data);
    });

  },[currentMonth,user.id]);

  useEffect(()=>{
    api.get<AppointmentDTO[]>('appointments/me',{
      params:{
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth() + 1, // + 1 porque o mes começa em 0
        day: selectedDate.getDate(),      
      }
    }).then(response =>{

      // formata a hora
      const appointmetsFormatted = response.data.map( appointment => {
        return {
          ...appointment,
          hourFormatted: format(parseISO(appointment.date),'HH:mm')
        };
      });

      setAppointments(appointmetsFormatted);      
    });
    

  },[selectedDate]);



  const disableDays = useMemo(()=>{
    const dates = monthAvailability
    .filter( monthDay => monthDay.available  === false)    // filtra so os dias que não estão disponiveis
    .map(monthDay => {
      const year = currentMonth.getFullYear();
      const month = currentMonth.getMonth();
      const day = Number(monthDay.day);
      return new Date( year, month, day);
    });
    return dates;
  },[currentMonth,monthAvailability]);

  const selectedDateAsText = useMemo(()=> {
    return format(selectedDate,"'Dia' dd 'de' MMMM ",{ locale:ptBR });
  },[selectedDate]);

  const selectedWeekDay = useMemo(()=> {
    return format(selectedDate,"cccc",{ locale:ptBR });
  },[selectedDate]);

  const morningAppointments = useMemo(()=> { // agendamentos da manhã
    return appointments.filter(appointment => {
      return parseISO(appointment.date).getHours() < 12;
    })
  },[appointments]);
  
  
  const afternoonAppointments = useMemo(()=> {  // Agendamentos da atarde
    return appointments.filter(appointment => {
      return parseISO(appointment.date).getHours() >= 12;
    })
  },[appointments]);


  const nextAppointment = useMemo(()=>{
    return appointments.find(appointment =>
        isAfter(parseISO(appointment.date),new Date()), // retorna o primeiro agendamento que a data por depois de agora 
    );
  },[appointments]);
  

 return ( <Container>
    <Header>
      <HeaderContet>
      <img src={logoImg} alt="GoBarber"/>
      <Profile>
        <img src={user.avatar_url} 
        alt={user.name}/>

        <div>
          <span>Bem vindo,</span>
          <Link to="/profile">
            <strong>{user.name}</strong>
          </Link>
        </div>
      </Profile>

        <button type="button" onClick={signOut}>
          <FiPower/>
        </button>
      </HeaderContet>
    </Header>
  
  <Content>
    <Schedule>
      <h1>Horários agendados</h1>  
      <p>
          {isToday(selectedDate) && <span>Hoje</span>}
          <span>{selectedDateAsText}</span>
          <span>{selectedWeekDay}</span>
      </p>
      
      {isToday(selectedDate) && nextAppointment &&  (
        <NextAppointment>
                <strong>Agendamento a seguir</strong>
                <div>
                  <img src={nextAppointment.user.avatar_url} alt={nextAppointment.user.name}/>
                  <strong>{nextAppointment.user.name}</strong>
                  <span>
                    <FiClock/>
                  {nextAppointment.hourFormatted}
                  </span>

                </div>
        </NextAppointment>
      )}

      

      <Section>
        <strong>Manhã</strong>

        {morningAppointments.length === 0 && (
          <p>Nenhum agendamento neste periodo</p>
        )}

        {morningAppointments.map(appointment =>(
          <Appointment key={appointment.id}>
            <span>
              <FiClock/>
              {appointment.hourFormatted}
            </span>
            <div>
              <img src={appointment.user.avatar_url} alt={appointment.user.name}/>
              <strong>{appointment.user.name}</strong>
            </div>
          </Appointment>
        ))}

      </Section>

      <Section>
        <strong>Tarde</strong>

        {afternoonAppointments.length === 0 && (
          <p>Nenhum agendamento neste periodo</p>
        )}


        {afternoonAppointments.map(appointment =>(
          <Appointment key={appointment.id}>
            <span>
              <FiClock/>
              {appointment.hourFormatted}
            </span>
            <div>
              <img src={appointment.user.avatar_url} alt={appointment.user.name}/>
              <strong>{appointment.user.name}</strong>
            </div>
          </Appointment>
        ))}
      </Section>


    </Schedule>
    <Calendar>
      <DayPicker
       weekdaysShort={['Dom','Seg','Ter','Qua','Qui','Sex','Sab']}
       fromMonth={new Date()}  // desativa a flechina para voltar a data      
       disabledDays={[         // dia da semana que queremos que fique desabilitado
         {daysOfWeek:[0,6]},   //// desabilita sabado e domingo, domingo é 0 , sabado é 6
         ...disableDays   
       ]}      
       modifiers={{
         available:{daysOfWeek:[1,2,3,4,5]}
       }}
       onDayClick={handleDateChange}
       selectedDays={selectedDate}
       onMonthChange={handleMonthChange}

       months={[
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'Junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Desembro',
        ]}
      
      />
    </Calendar>  
  </Content>  

  </Container>);

}

export default DashBoard;