import React, { useCallback, useRef } from 'react';

import logoImg from '../../assets/logo.svg';

import {FiLock} from 'react-icons/fi';

import {Form} from '@unform/web';

import Input from '../../components/Input';

import Button from '../../components/Button';

import {FormHandles} from '@unform/core'; // tipagem de todas as funções

import * as Yup from 'yup';

import getValidationErrors from '../../utils/getValidationErrors';



import {useToast} from '../../hooks/toast';

import {Container,Content,BackGround, AnimationContainer} from './styles';

import {useHistory, useLocation}  from 'react-router-dom'; // pra fazer a nevagação
import api from '../../services/api';


interface ResetPasswordFormData{
  password:string;
  password_confirmation:string ;
}

const ResetPassword: React.FC = () => {

  const history = useHistory(); 
  const location = useLocation();

  console.log(location);

  const { addToast } = useToast();  

  const formRef = useRef<FormHandles>(null); 

    const handleSubmit = useCallback(async (data: ResetPasswordFormData) => {
       
     try {

        formRef.current?.setErrors({}); // limpando os formulários de erros.

  	    const schema = Yup.object().shape({      
        password: Yup.string().required( 'Digite a senha'),

        password_confirmation: Yup.string()
     .oneOf([Yup.ref('password'), ''], 'Digite a mesma senha de cima.')

        
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      //chamada a api

      
      const token = location.search.replace('?token=','');

      if(!token){        
        addToast({
          type:'error',
          title:'Erro ao resetar senha',
          description:'O token não foi encontrado na URL',                
        }); 
      }

      await api.post('/password/reset',
        {
          password:data.password,
          password_confirmation:data.password_confirmation,
          token:token,
        }
      )

 

      history.push('/'); // volta sem fazer dar refresh

    } catch (err) {

      if (err instanceof Yup.ValidationError){ // se for erro do usuario 

        const errors = getValidationErrors(err); // retorna um erro personalizado     
  
        formRef.current?.setErrors(errors);

        return;
      }          
      // disparar um toast
      addToast({
        type:'error',
        title:'Erro ao resetar senha',
        description:'Ocorreu um erro ao rerester sua senha, tente novamente',                
      }); 
    }

  }, [addToast,history, location.search]);

  return (
        <Container>
        <Content>
            <AnimationContainer>          
              <img src={logoImg} alt="Gobarber"/>
              <Form ref={formRef} onSubmit = {handleSubmit} >
                <h1>Resetar senha</h1>
               
                <Input name="password" icon={FiLock} type="password" placeholder="Crie uma senha" />
                <Input name="password_confirmation" icon={FiLock} type="password" placeholder="Repita a senha"/>

                <Button type="submit">Alterar senha</Button>
                
              </Form>
              
            </AnimationContainer>
        </Content>
        <BackGround/>
      </Container>
    );
  }

export default ResetPassword;