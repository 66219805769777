import styled from 'styled-components';

import { shade } from 'polished'; // lib para lidar com cores

export const Container = styled.div``;

export const Header = styled.header`
  
  padding:32px 0; 
  background:#28262e;

`;

export const HeaderContet = styled.div`
    /* max-width: 1120px; // largura maxima */
    margin: 0 auto; // alinhar no centro
    display: flex; // colocar as coisas um ao lado da outra
    align-items: center; // não deixar esticar as coisas na tela
    > img {   // primeira imagem
        height:80px; // para logo ficar menor
    }

    // botão de sair
    button{
      margin-left: auto;
      background: transparent;
      border: 0;

      svg{ 
        color:#999591; 
        width:20px;
        height:20px;
       }
    
    }

  
    

`;

export const Profile = styled.div`
  display:flex; // tudo lado a lado
  align-items: center;
  margin-left: 80px; // distancia da logo

  img{
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  div{
    display:flex;
    flex-direction:column;
    margin-left: 16px;
    line-height: 24px;

    span{
      color:#f4ede8;
    }

    a {
      text-decoration:none;      
      color:#f4ede8;

      &:hover{
        opacity: 0.8;
      }

    }

  }

  
`;


  export const Content = styled.main`
      max-width:1120px;
      margin:64px auto;
      display:flex; // para Schedule e Calendar ficar lado a lado
  `;
  
  export const Schedule = styled.div`
    flex:1; // para ocupar todo o espaço disponivel
    margin-right: 120px;

    h1{
      font-size:36px;
    }

    p{
      margin-top: 8px;
      color:#ff9000;
      display:flex;
      align-items:center;
      font-weight:500;
    }

    /* span + span{
      margin-left:8px;
      padding-left:8px;
      border-left: 1px solid #ff9000;
    } */

    span{
      display:flex;
      align-items:center;
    }
  

    span + span::before{
      content:'';
      width:1px;
      height:12px;
      background:#ff9000;
      margin:8px;

    }

  `;
  
 

  export const NextAppointment = styled.div`
    margin-top:64px;
    > strong{   // > para estilizar só esse
      color:#999591;
      font-size:20px;
      font-weight:400;
    }

    div{
      background:#3e3b47;
      display:flex;
      align-items:center;
      padding: 16px 24px; // 16px em baixo e em cima 24px nas laterais
      border-radius:10px; // aredondar bordas
      margin-top: 24px;   // distancia da parte de cima
      position:relative;  // para poder usar o before

      &::before{
        position: absolute;
        height: 80%;   // pra ficar um pouco menor que o tamanho total
        width:1px;
        left: 0;  // espaço da esquerda
        top:10% ;  // distancia do topo fica no meio porque 100% -20% = 10%, já que temos ele 80% do total 
        content:'';
        background-color:#ff9000; 
      }

      img{ // a imagem está definindo o tamanho do componente;
        width:80px;   
        height:80px;
        border-radius:50%;
      }

      strong{
        margin-left:24px; // distancia da esquerta
        color:#fff;
      }

      span{
        margin-left:auto; // jogar tudo pra esquerda
        display:flex; // 
        align-items: center; // 
        color: #999591;

        svg{
          color:#ff9000;
          margin-right: 8px; // distancia da direita
        }

        
      }

    }
  `; 

  export const Section = styled.div`
      margin-top:48px;
      >strong{
        color: #999591;
        font-size: 20px;
        //line-height:26px; 
        border-bottom: 1px solid #3e3b47;   // risquinho em baixo do nome
        display:block;                      // para ocupar toda a linha
        padding-bottom: 16px;               // espaço da linha em baixo
        margin-bottom: 16px;                // afasta o conteudo da borda

      }

      > P{
        color: #999591
      }

  `;



  export const Appointment = styled.div`
      display:flex;
      align-items:center;

      & + div{             //aplica o estilo abaixo apartir do segundo item
        margin-top: 16px; // 
      }

      span{
        margin-left:auto; // jogar tudo pra esquerda
        display:flex; // 
        align-items: center; // 
        color: #f4ede8;
        width: 70px;

        svg{
          color:#ff9000;
          margin-right: 8px; // distancia da direita
        }        
      }

      div{
        flex:1;
        background:#3e3b47;
        display:flex;
        align-items:center;
        padding: 16px 24px; // 16px em baixo e em cima 24px nas laterais
        border-radius:10px; // aredondar bordas
        margin-left: 24px;   // distancia da esquerta

        img{ // a imagem está definindo o tamanho do componente;
            width:56px;   
            height:56px;
            border-radius:50%;
        }

        strong{
          margin-left:24px; // distancia da esquerta
          color:#fff;
          font-size: 20px; 
        }       
      }
  `;


  export const Calendar = styled.aside`
    width:380px;

    .DayPicker {
  background: #28262e;
  border-radius: 10px;
}

.DayPicker-wrapper {
  padding-bottom: 0;
}

.DayPicker,
.DayPicker-Month {
  width: 100%;
}

.DayPicker-Month {
  border-collapse: separate;
  border-spacing: 8px;
  margin: 16px;
}

.DayPicker-Day {
  width: 40px;
  height: 40px;
}

.DayPicker-Day--available:not(.DayPicker-Day--outside) {
  background: #3e3b47;
  border-radius: 10px;
  color: #fff;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background: ${shade(0.2, '#3e3b47')};
}

.DayPicker-Day--today {
  font-weight: normal;
}

.DayPicker-Day--disabled {
  color: #666360 !important;
  background: transparent !important;
}

.DayPicker-Day--selected {
  background: #ff9000 !important;
  border-radius: 10px;
  color: #232129 !important;
}
  `; 



  //export const _ = styled.div``;

