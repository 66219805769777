import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>; // type subistitui a interface já que não vai sobrescrever nada no interface.

const Button: React.FC<ButtonProps> = ({ children, ...rest }) => { // children é o conteudo do texto do botão, rest é o resto das props
  return (
    <Container type="button" {...rest}>
      {children}
    </Container>
  );
};

export default Button;