import React, { InputHTMLAttributes, useEffect,useRef,useState,useCallback } from 'react';
import { IconBaseProps } from 'react-icons'; // importa os tipos do icone para o intelesense funcionar
import {useField} from '@unform/core'; // hook para receber no nome do campo
import { FiAlertCircle } from 'react-icons/fi';



import { Container, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> { // extendendo imput do html 
  name: string; // sobrescrever colocando nome obrigatóro , se colocar a interrogação fica opcional
  containerStyle?: object;
  icon?: React.ComponentType<IconBaseProps>; // React.ComponentType recebe um compotente como propriedade, IconBaseProps ativa o intelesense no icone,  ?"interrogação deixa o variavel como opcional" 
}

const Input: React.FC<InputProps> = ({ name, containerStyle={}, icon: Icon, ...rest }) => { // icon: Icon,  troca o icone para letra maiuscula porque o react só aceita componentes em letra maiuscula
  
  const inputRef = useRef<HTMLInputElement>(null); // referencia para acessar o elemento na doom e pegar o valor diretamente e fazer manipulação, HTMLInputElement disponibiliza o intelesense com os campos

  const [isFocused, setIsFocused] = useState(false); // está focado

  const [isFilled, setIsFilled] = useState(false); // está preenchido

  const { fieldName, defaultValue, error , registerField} = useField(name);

  useEffect(()=>{
      registerField({
        name:fieldName,       // nome do campo ára fazer a referencia
        ref:inputRef.current, // é o que dá acesso ao impout no html
        path:'value', // indica para o unform onde vai ficar o valor do imput, path        
      });
  },[fieldName,registerField]);

  const handleInputBlur = useCallback(()=> {

    setIsFocused(false);    
    // verifcar se está preenchido ou não
    setIsFilled(!!inputRef.current?.value); //!! tranforma para boleano e inverte o resultado

  },[]);// [] indica que ele não deve monitorar nenhuma variavel, ou seja não vai ser recriado
  
  
  const handleInputFocus = useCallback(()=> {
    setIsFocused(true);    

  },[]);
  

  return (
    <Container  style={containerStyle} isErrored = {!!error} isFilled={isFilled} isFocused={isFocused}>
      {Icon && <Icon size={20} />}
      <input 
      onFocus={handleInputFocus} // evento imput recebeu o focus
      onBlur={handleInputBlur} // evento imput perde o focus
      defaultValue= {defaultValue}      
      ref={inputRef} {...rest} />
   
      {error && ( // se error existir então mostre abaixo
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}  
     
    </Container>
  );
};

export default Input;