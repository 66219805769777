import React, { useCallback, useRef } from 'react';

//import logoImg from '../../assets/logo.svg';

import {FiLogIn,FiMail} from 'react-icons/fi';

import {Form} from '@unform/web';

import Input from '../../components/Input';

import Button from '../../components/Button';

import {FormHandles} from '@unform/core'; // tipagem de todas as funções

import * as Yup from 'yup';

import getValidationErrors from '../../utils/getValidationErrors';


import {useToast} from '../../hooks/toast';

import {Container,Content, AnimationContainer} from './styles';

import {Link}  from 'react-router-dom'; // pra fazer a nevagação
import api from '../../services/api';


interface ForgotPasswordFormData{
  email:string;
  password:string;
}

const ForgotPassword: React.FC = () => {

  
  const { addToast } = useToast();  

  const formRef = useRef<FormHandles>(null); 

    const handleSubmit = useCallback(async (data: ForgotPasswordFormData) => {
       
     try {

        formRef.current?.setErrors({}); // limpando os formulários de erros.

  	    const schema = Yup.object().shape({
        email: Yup.string()
          .email('Digite um e-mail válido')
          .required('E-mail obrigatório'),
      
      });

      await schema.validate(data, {
        abortEarly: false,
      });

     // recuperação de senha

     await api.post('/password/forgot',{
       email: data.email,
     }); 

     addToast({
       type:'success',
       title:'Email de recuperação de senha',
       description: 'enviamos um email para a recuperação da senha'
     })

   


    } catch (err) {

      if (err instanceof Yup.ValidationError){ // se for erro do usuario 

        const errors = getValidationErrors(err); // retorna um erro personalizado     
  
        formRef.current?.setErrors(errors);

        return;
      }          
      // disparar um toast
      addToast({
        type:'error',
        title:'Erro na recuperação de senha',
        description:'Ocorreu um erro ao tentar fazer a recuperação de senha tente novamente',                
      }); 
    }

  }, [addToast]
  );

  return (
        <Container>
        <Content>
            <AnimationContainer>          
              {/* <img src={logoImg} alt="Gobarber"/> */}
              <Form ref={formRef} onSubmit = {handleSubmit} >
                <h1>Recuperar senha</h1>
                <Input name="email" icon={FiMail} placeholder="E-mail"/>                
                <Button type="submit">Recuperar</Button>
                
              </Form>
              <Link to="/">
                <FiLogIn/>
                Voltar ao login
              </Link>
            </AnimationContainer>
        </Content>
        
      </Container>
    );
  }

export default ForgotPassword;