import styled from 'styled-components';

export const Container = styled.div` 
    position: relative; // alinhar todos componente apartir deste
    span{
      width:160px;
      background: #ff9000;
      padding: 8px; // espaço em todos os lados
      border-radius: 4px; // arredondar as bordas
      font-size: 14px;
      font-weight: 500; // negrito
      opacity:0; // visibilidade
      transition:opacity 0.4; 
      visibility:hidden; // esconde do elemento quando ele não estiver aparecendo para o hover não ativar sem querer

      position:absolute; 
      bottom: calc (100% + 12px); // em ciuma da imagem

      // centralizar
      left: 50%; // coloca  tudo pra direira
      transform:translateX(-50%); // volta
      color: #312e38;
      bottom:30px;

      // fazer a setinha para baixo

      &::before{
        content:''; // se não colocar isso ele não aparece em tela
        border-style:solid;
        border-color:#ff9000 transparent;
        border-width: 6px 6px 0 6px;       
        top:100%;
        position:absolute;
        // centralizando  a sentinha desenhada acima
        left: 50%; // coloca  tudo pra direira
        transform:translateX(-50%); // volta
      }
    }

    &:hover span {
      opacity:1;
      visibility:visible;
    }
 `;